<template>
  <!-- 投递记录 -->
  <div class="talentBg">
    <section>
      <el-row class="talentIndex" :gutter="16">
        <el-col :span="8">
          <talentInfo></talentInfo>
        </el-col>
        <el-col :span="16">
          <div class="info-card">
            <el-tabs v-model="activeName" @tab-click="tabsClick">
              <el-tab-pane label="投递成功" name="first">
                <el-radio-group
                  v-model="comType"
                  size="small"
                  @change="changecomType"
                  style="margin-bottom: 8px;margin-right:16px"
                >
                  <el-radio-button label="企业"></el-radio-button>
                  <el-radio-button label="猎头"></el-radio-button>
                </el-radio-group>
                <el-radio-group
                  v-model="workType"
                  size="small"
                  @change="changeWorkType"
                  style="margin-bottom: 8px;"
                >
                  <el-radio-button label="兼职"></el-radio-button>
                  <el-radio-button label="全职"></el-radio-button>
                </el-radio-group>
                <div v-if="partBox">
                  <el-table :data="partData" class="partTimeTable">
                    <el-table-column width="450px">
                      <template slot-scope="scope">
                        <div style="padding-left:10px">
                          <div class="cername">
                            <span
                              @click="gopartTime(scope.row.job_info.id)"
                              class="font-blod cursor fontHover"
                            >
                              {{ scope.row.job_info.j_certificate }}-{{
                                scope.row.job_info.j_major
                              }}
                            </span>
                          </div>
                          <div class="cerstate">
                            <span class="cerPay font-orange">
                              {{ scope.row.job_info.j_min_price }}万-{{
                                scope.row.job_info.j_max_price
                              }}万/{{ scope.row.job_info.j_sign_time }}
                            </span>
                            <span>
                              <span
                                v-if="
                                  scope.row.job_info.j_social_need == '不限'
                                "
                              >
                                社保</span
                              >{{ scope.row.job_info.j_social_need }}
                            </span>
                            <span
                              >{{ scope.row.job_info.j_area_province }}-{{
                                scope.row.job_info.j_area_city
                              }}</span
                            >
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column>
                      <template slot-scope="scope">
                        <div class="text-right">
                          <div class="comName">
                            <span>{{ scope.row.com_name }}</span>
                          </div>
                          <div>
                            <span style="color: #2573F1;">
                              投递时间:{{ scope.row.delivery_time }}
                            </span>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <div slot="empty" class="empty">
                      <div>
                        <img :src="noDate" />
                      </div>
                      <span>暂无数据</span>
                    </div>
                  </el-table>
                  <div class="pageBox">
                    <el-pagination
                      background
                      @size-change="sizeChange1"
                      @current-change="currentChange1"
                      :current-page="currentPage"
                      :page-sizes="[10, 20]"
                      :page-size="pagesize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="tableTotal"
                    >
                    </el-pagination>
                  </div>
                </div>
                <div v-if="fullBox">
                  <el-table :data="fullData" class="fullTimeTable">
                    <el-table-column width="450px">
                      <template slot-scope="scope">
                        <div style="padding-left:10px">
                          <div class="cername">
                            <span
                              @click="gofullTime(scope.row.job_info.id)"
                              class="font-blod cursor fontHover"
                            >
                              {{ scope.row.job_info.j_certificate }}-{{
                                scope.row.job_info.j_major
                              }}
                            </span>
                          </div>
                          <div class="cerstate">
                            <span class="cerPay font-orange">
                              {{ scope.row.job_info.j_min_price }}K-{{
                                scope.row.job_info.j_max_price
                              }}K
                            </span>
                            <span>
                              {{ scope.row.job_info.j_work_experience }}
                            </span>
                            <span>
                              {{ scope.row.job_info.j_education_need }}
                            </span>
                            <span
                              >{{ scope.row.job_info.j_province }}-{{
                                scope.row.job_info.j_city
                              }}</span
                            >
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column>
                      <template slot-scope="scope">
                        <div class="text-right">
                          <div class="comName">
                            <span>{{ scope.row.com_name }}</span>
                          </div>
                          <div>
                            <span style="color: #2573F1;">
                              投递时间:{{ scope.row.delivery_time }}
                            </span>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <div slot="empty" class="empty">
                      <div>
                        <img :src="noDate" />
                      </div>
                      <span>暂无数据</span>
                    </div>
                  </el-table>
                  <div class="pageBox">
                    <el-pagination
                      background
                      @size-change="sizeChange2"
                      @current-change="currentChange2"
                      :current-page="currentPage2"
                      :page-sizes="[10, 20]"
                      :page-size="pagesize2"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="tableTotal2"
                    >
                    </el-pagination>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="被查看" name="second">
                <el-radio-group
                  v-model="comType"
                  size="small"
                  @change="changecomType"
                  style="margin-bottom: 8px;margin-right:16px"
                >
                  <el-radio-button label="企业"></el-radio-button>
                  <el-radio-button label="猎头"></el-radio-button>
                </el-radio-group>
                <el-radio-group
                  v-model="workType"
                  size="small"
                  @change="changeWorkType"
                  style="margin-bottom: 8px;"
                >
                  <el-radio-button label="兼职"></el-radio-button>
                  <el-radio-button label="全职"></el-radio-button>
                </el-radio-group>
                <div v-if="partBox">
                  <el-table :data="partData" class="partTimeTable">
                    <el-table-column width="450px">
                      <template slot-scope="scope">
                        <div style="padding-left:10px">
                          <div class="cername">
                            <span
                              @click="gopartTime(scope.row.job_info.id)"
                              class="font-blod cursor fontHover"
                            >
                              {{ scope.row.job_info.j_certificate }}-{{
                                scope.row.job_info.j_major
                              }}
                            </span>
                          </div>
                          <div class="cerstate">
                            <span class="cerPay font-orange">
                              {{ scope.row.job_info.j_min_price }}万-{{
                                scope.row.job_info.j_max_price
                              }}万/{{ scope.row.job_info.j_sign_time }}
                            </span>
                            <span>
                              <span
                                v-if="
                                  scope.row.job_info.j_social_need == '不限'
                                "
                              >
                                社保</span
                              >{{ scope.row.job_info.j_social_need }}
                            </span>
                            <span
                              >{{ scope.row.job_info.j_area_province }}-{{
                                scope.row.job_info.j_area_city
                              }}</span
                            >
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column>
                      <template slot-scope="scope">
                        <div class="text-right">
                          <div class="comName">
                            <span>{{ scope.row.com_name }}</span>
                          </div>
                          <div>
                            <span style="color: #2573F1;">
                              投递时间:{{ scope.row.delivery_time }}
                            </span>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <div slot="empty" class="empty">
                      <div>
                        <img :src="noDate" />
                      </div>
                      <span>暂无数据</span>
                    </div>
                  </el-table>
                  <div class="pageBox">
                    <el-pagination
                      background
                      @size-change="sizeChange1"
                      @current-change="currentChange1"
                      :current-page="currentPage"
                      :page-sizes="[10, 20]"
                      :page-size="pagesize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="tableTotal"
                    >
                    </el-pagination>
                  </div>
                </div>
                <div v-if="fullBox">
                  <el-table :data="fullData" class="fullTimeTable">
                    <el-table-column width="450px">
                      <template slot-scope="scope">
                        <div style="padding-left:10px">
                          <div class="cername">
                            <span
                              @click="gofullTime(scope.row.job_info.id)"
                              class="font-blod cursor fontHover"
                            >
                              {{ scope.row.job_info.j_certificate }}-{{
                                scope.row.job_info.j_major
                              }}
                            </span>
                          </div>
                          <div class="cerstate">
                            <span class="cerPay font-orange">
                              {{ scope.row.job_info.j_min_price }}K-{{
                                scope.row.job_info.j_max_price
                              }}K
                            </span>
                            <span>
                              {{ scope.row.job_info.j_work_experience }}
                            </span>
                            <span>
                              {{ scope.row.job_info.j_education_need }}
                            </span>
                            <span
                              >{{ scope.row.job_info.j_province }}-{{
                                scope.row.job_info.j_city
                              }}</span
                            >
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column>
                      <template slot-scope="scope">
                        <div class="text-right">
                          <div class="comName">
                            <span>{{ scope.row.com_name }}</span>
                          </div>
                          <div>
                            <span style="color: #2573F1;">
                              投递时间:{{ scope.row.delivery_time }}
                            </span>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <div slot="empty" class="empty">
                      <div>
                        <img :src="noDate" />
                      </div>
                      <span>暂无数据</span>
                    </div>
                  </el-table>
                  <div class="pageBox">
                    <el-pagination
                      background
                      @size-change="sizeChange2"
                      @current-change="currentChange2"
                      :current-page="currentPage2"
                      :page-sizes="[10, 20]"
                      :page-size="pagesize2"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="tableTotal2"
                    >
                    </el-pagination>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="邀请面试" name="third">
                <el-radio-group
                  v-model="comType"
                  size="small"
                  @change="changecomType"
                  style="margin-bottom: 8px;margin-right:16px"
                >
                  <el-radio-button label="企业"></el-radio-button>
                  <el-radio-button label="猎头"></el-radio-button>
                </el-radio-group>
                <el-radio-group
                  v-model="workType"
                  size="small"
                  @change="changeWorkType"
                  style="margin-bottom: 8px;"
                >
                  <el-radio-button label="兼职"></el-radio-button>
                  <el-radio-button label="全职"></el-radio-button>
                </el-radio-group>
                <div v-if="partBox">
                  <el-table :data="partData" class="partTimeTable">
                    <el-table-column width="450px">
                      <template slot-scope="scope">
                        <div style="padding-left:10px">
                          <div class="cername">
                            <span
                              @click="gopartTime(scope.row.job_info.id)"
                              class="font-blod cursor fontHover"
                            >
                              {{ scope.row.job_info.j_certificate }}-{{
                                scope.row.job_info.j_major
                              }}
                            </span>
                          </div>
                          <div class="cerstate">
                            <span class="cerPay font-orange">
                              {{ scope.row.job_info.j_min_price }}万-{{
                                scope.row.job_info.j_max_price
                              }}万/{{ scope.row.job_info.j_sign_time }}
                            </span>
                            <span>
                              <span
                                v-if="
                                  scope.row.job_info.j_social_need == '不限'
                                "
                              >
                                社保</span
                              >{{ scope.row.job_info.j_social_need }}
                            </span>
                            <span
                              >{{ scope.row.job_info.j_area_province }}-{{
                                scope.row.job_info.j_area_city
                              }}</span
                            >
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column>
                      <template slot-scope="scope">
                        <div class="text-right">
                          <div class="comName">
                            <span>{{ scope.row.com_name }}</span>
                          </div>
                          <div>
                            <span style="color: #2573F1;">
                              投递时间:{{ scope.row.delivery_time }}
                            </span>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <div slot="empty" class="empty">
                      <div>
                        <img :src="noDate" />
                      </div>
                      <span>暂无数据</span>
                    </div>
                  </el-table>
                  <div class="pageBox">
                    <el-pagination
                      background
                      @size-change="sizeChange1"
                      @current-change="currentChange1"
                      :current-page="currentPage"
                      :page-sizes="[10, 20]"
                      :page-size="pagesize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="tableTotal"
                    >
                    </el-pagination>
                  </div>
                </div>
                <div v-if="fullBox">
                  <el-table :data="fullData" class="fullTimeTable">
                    <el-table-column width="450px">
                      <template slot-scope="scope">
                        <div style="padding-left:10px">
                          <div class="cername">
                            <span
                              @click="gofullTime(scope.row.job_info.id)"
                              class="font-blod cursor fontHover"
                            >
                              {{ scope.row.job_info.j_certificate }}-{{
                                scope.row.job_info.j_major
                              }}
                            </span>
                          </div>
                          <div class="cerstate">
                            <span class="cerPay font-orange">
                              {{ scope.row.job_info.j_min_price }}K-{{
                                scope.row.job_info.j_max_price
                              }}K
                            </span>
                            <span>
                              {{ scope.row.job_info.j_work_experience }}
                            </span>
                            <span>
                              {{ scope.row.job_info.j_education_need }}
                            </span>
                            <span
                              >{{ scope.row.job_info.j_province }}-{{
                                scope.row.job_info.j_city
                              }}</span
                            >
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column>
                      <template slot-scope="scope">
                        <div class="text-right">
                          <div class="comName">
                            <span>{{ scope.row.com_name }}</span>
                          </div>
                          <div>
                            <span style="color: #2573F1;">
                              投递时间:{{ scope.row.delivery_time }}
                            </span>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <div slot="empty" class="empty">
                      <div>
                        <img :src="noDate" />
                      </div>
                      <span>暂无数据</span>
                    </div>
                  </el-table>
                  <div class="pageBox">
                    <el-pagination
                      background
                      @size-change="sizeChange2"
                      @current-change="currentChange2"
                      :current-page="currentPage2"
                      :page-sizes="[10, 20]"
                      :page-size="pagesize2"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="tableTotal2"
                    >
                    </el-pagination>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
      </el-row>
    </section>
  </div>
</template>
<script>
import talentInfo from "@/view/talentPage/talentInfo.vue";
export default {
  data() {
    return {
      noDate: require("@/assets/img/noDate.png"),
      activeName: "first",
      workType: "兼职",
      comType: "企业",
      partData: [],
      fullData: [],
      currentPage: 1,
      pagesize: 10,
      tableTotal: 0,
      currentPage2: 1,
      pagesize2: 10,
      tableTotal2: 0,
      partBox: true,
      fullBox: false,
      searchInfo: {
        com_type: 2,
        type: 1,
      },
      searchInfo2: {
        com_type: 2,
        type: 2,
      },
    };
  },
  components: {
    talentInfo,
  },
  methods: {
    // 切换显示兼职全职
    changeWorkType(e) {
      if (e == "兼职") {
        this.fullBox = false;
        this.partBox = true;
      } else {
        this.partBox = false;
        this.fullBox = true;
      }
    },
    changecomType(e) {
      if (e == "企业") {
        this.searchInfo.com_type = 2;
        this.getPartTime();
        this.searchInfo2.com_type = 2;
        this.getFullTime();
      } else {
        this.searchInfo.com_type = 3;
        this.getPartTime();
        this.searchInfo2.com_type = 3;
        this.getFullTime();
      }
    },
    // tab切换
    tabsClick(e) {
      if (e.$options.propsData.label == "投递成功") {
        this.searchInfo.viewed = "";
        this.searchInfo2.viewed = "";
        this.searchInfo.interview = "";
        this.searchInfo2.interview = "";
        this.getPartTime();
        this.getFullTime();
      } else if (e.$options.propsData.label == "被查看") {
        this.searchInfo.viewed = 1;
        this.searchInfo2.viewed = 1;
        this.searchInfo.interview = "";
        this.searchInfo2.interview = "";
        this.getPartTime();
        this.getFullTime();
      } else if (e.$options.propsData.label == "邀请面试") {
        this.searchInfo.viewed = "";
        this.searchInfo2.viewed = "";
        this.searchInfo.interview = 1;
        this.searchInfo2.interview = 1;
        this.getPartTime();
        this.getFullTime();
      }
    },
    // 收藏兼职列表
    getPartTime(page, limit) {
      if (page == undefined || limit == undefined) {
        this.currentPage = 1;
        this.pagesize = 10;
      }
      this.searchInfo.page = this.currentPage;
      this.searchInfo.limit = this.pagesize;

      this.$http
        .post("talent/index/mydelivery", this.searchInfo)
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.partData = res.data.resultData.list;
            this.tableTotal = parseInt(res.data.resultData.count);
          }
        });
    },
    sizeChange1(e) {
      this.pagesize = e;
      this.currentPage = 1;
      this.getPartTime(this.currentPage, this.pagesize);
    },
    currentChange1(e) {
      this.currentPage = e;
      this.getPartTime(e, this.pagesize);
    },
    // 收藏全职列表
    getFullTime(page, limit) {
      if (page == undefined || limit == undefined) {
        this.currentPage2 = 1;
        this.pagesize2 = 10;
      }
      this.searchInfo2.page = this.currentPage2;
      this.searchInfo2.limit = this.pagesize2;

      this.$http
        .post("talent/index/mydelivery", this.searchInfo2)
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.fullData = res.data.resultData.list;
            this.tableTotal2 = parseInt(res.data.resultData.count);
          }
        });
    },
    sizeChange2(e) {
      this.pagesize2 = e;
      this.currentPage2 = 1;
      this.getFullTime(this.currentPage2, this.pagesize2);
    },
    currentChange2(e) {
      this.currentPage2 = e;
      this.getFullTime(e, this.pagesize2);
    },
    // 兼职简历详情
    gopartTime(id) {
      // this.$router.push({
      //   name: "partTimeInfo",
      //   query: { id: id },
      // });
      let routeUrl = this.$router.resolve({
        name: "partTimeInfo",
        query: { id: id },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 全职简历详情
    gofullTime(id) {
      // this.$router.push({
      //   name: "fullTimeInfo",
      //   query: { id: id },
      // });
      let routeUrl = this.$router.resolve({
        name: "fullTimeInfo",
        query: { id: id },
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  mounted() {
    this.getPartTime();
    this.getFullTime();
  },
};
</script>
<style scope>
.talentIndex {
  padding-top: 24px;
}
.cerstate > span {
  margin-right: 15px;
}
/deep/ .partTimeTable .el-table__header-wrapper,
/deep/ .fullTimeTable .el-table__header-wrapper {
  height: 0;
}
</style>
